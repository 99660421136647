import React from "react";

import DynamicComponentProps from "../../../Models/Props/dynamicComponentProps";
import AccountPin from "../../Steps/AccountPin/AccountPin";
import AddressCheck from "../../Steps/AddressCheck/AddressCheck";
import ContactCustomerService from "../../Errors/ContactCustomerService/ContactCustomerService";
import SelectCommunity from "../../Steps/SelectCommunity/SelectCommunity";
import SetUpAccountAccess from "../../Steps/SetUpAccountAccess/SetUpAccountAccess";
import Stepper from "../../Stepper/Stepper";
import Success from "../../Steps/Success/Success";
import UnknownStep from "../../Steps/UnknownStep/UnknownStep";
import ZipCode from "../../Steps/ZipCode/ZipCode";

const components = {
  AccountPin,
  AddressCheck,
  ContactCustomerService,
  SelectCommunity,
  SetUpAccountAccess,
  Stepper,
  Success,
  UnknownStep,
  ZipCode,
};

const DynamicComponent = (props: DynamicComponentProps) => {
  const TagName = components[props.componentName];
  return TagName ? <TagName /> : <ContactCustomerService />;
};

export default DynamicComponent;
