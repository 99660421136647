import { Theme } from "@mui/material";

import DefaultTheme from "./DefaultTheme";

const themes = {
  DefaultTheme,
};

const getTheme = (registrationTypeID: number) => {
  let theme: Theme;
  switch (registrationTypeID) {
    case 2:
      theme = themes["DefaultTheme"];
      break;
    default:
      theme = themes["DefaultTheme"];
      break;
  }

  return theme;
};

export default getTheme;
