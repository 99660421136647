import RedirectUrlInfo from "../Models/redirectUrlInfo";

const redirectUrl = (
  url: RedirectUrlInfo,
  pageURL: string,
  orginURL: string
) => {
  
  let lowerPageURL = pageURL.toLowerCase();

  if (lowerPageURL.includes("localhost") || lowerPageURL.includes("webdev")) {
    return;
  }

 //window.location.href = pageURL.replace(orginURL, url.defaultUrl);
};

export default redirectUrl;
